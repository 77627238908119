

/*------------------------------------------
        Hero Section Start Here
-------------------------------------------*/

.main-hero-dark{
    .hero-main-inr{
        .nav-otr{
            position: relative;
            .container-fluid{
                .nav-inr{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 32px 0;
                    @include breakpoint($secreen-max-md){
                        padding: 24px 0;
                    }
                    .burger-menu{
                        display: flex;
                        align-items: center;
                        .burger-icon-otr{
                            width: 52px;
                            height: 52px;
                            border-radius: 100%;
                            background-color: $Dark-bg-quaternary;
                            position: relative;
                            margin-right: 12px;
                            .burger-icon{
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                &:hover{
                                    cursor: pointer;
                                    path{
                                        stroke: $Primary-Color-Home-1;
                                    }
                                }
                                path{
                                    stroke: $Gray-Shade-2;
                                    transition: .3s;
                                }
                            }
                        }
                        .logo-otr{
                            .logo-img{
                                width: 100px;
                            }
                        }
                    }
                    .input-main{
                        position: relative;
                        flex: 1;
                        margin: 0 30px;
                        @include breakpoint($secreen-max-sm){
                            display: none;
                        }
                        .input{
                            width: 100%;
                            padding-right: 52px;
                            background-color: $Dark-bg-quaternary;
                            color: $Dark-Gray-Shade-2;
                            &::placeholder{
                                color: $Dark-Gray-Shade-2;
                            }
                        }
                        .search-icon{
                            position: absolute;
                            right: 24px;
                            top: 50%;
                            transform: translateY(-50%);
                            path{
                                stroke: $Dark-Gray-Shade-2;
                            }
                        }
                    }
                    .search-circle{
                        width: 52px;
                        height: 52px;
                        background-color: $Dark-bg-quaternary;
                        border-radius: 100%;
                        position: relative;
                        @include breakpoint($secreen-min-sm){
                            display: none;
                        }
                        .search-icon{
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            path{
                                stroke: $Dark-Gray-Shade-2;
                            }
                        }
                    }
                    .modal-input{
                        .modal-dialog-input{
                            .modal-content-input{
                                background-color: $Dark-bg-quaternary;
                                border: none;
                                width: 100%;
                                .input-modal{
                                    background-color: $Dark-Black-2-Color;
                                    border: none;
                                    width: 100%;
                                }
                            }
                        }
                    }
                    .action-otr{
                        display: flex;
                        align-items: center;
                        @include breakpoint($secreen-max-md){
                            display: none;
                        }
                        .btn-create{
                            margin-right: 16px;
                        }
                        .btn-wallet{
                            color: $White-Color;
                        }
                    }
                }
            }
        }
        .hero-mainn{
            overflow: hidden;
            position: relative;
            margin: 0 0 128px 0;
            @include breakpoint($secreen-max-sm){
                margin: 0 0 80px 0;
            }
            .container-fluid{
                padding: 0 100px !important;
                @include breakpoint($secreen-max-xxl){
                    padding: 0 15px !important;
                }
                @include breakpoint($secreen-max-md){
                    padding: 0;
                }
                .hero-inr{
                    padding: 96px 0;
                    position: relative;
                    z-index: 10;
                    @include breakpoint($secreen-max-md){
                        padding: 0;
                    }
                    .row-custom{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        @include breakpoint($secreen-max-md){
                            flex-direction: column-reverse;
                        }
                        .col-content-otr{
                            @include breakpoint($secreen-max-xxl){
                                width: 56.333333% !important;
                            }
                            @include breakpoint($secreen-max-md){
                                width: 100% !important;
                            }
                            .col-content-inr{
                                @include breakpoint($secreen-max-md){
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    margin: 72px 0 0 0;
                                }
                                @include breakpoint($secreen-max-sm){
                                    align-items: inherit;
                                }
                                .head-otr{
                                    position: relative;
                                    .heading{
                                        -webkit-background-clip: text;
                                        -webkit-text-fill-color: transparent;
                                        background-image: linear-gradient(to right, #366CE3, #D0FC00);
                                        position: relative;
                                        z-index: 2;
                                        @include breakpoint($secreen-max-md){
                                            text-align: center;
                                        }
                                        @include breakpoint($secreen-max-sm){
                                            text-align: left;
                                        }
                                    }
                                }
                                .boxes-main{
                                    display: flex;
                                    margin: 32px 0 40px 0;
                                    @include breakpoint($secreen-max-sm){
                                        flex-direction: column;
                                    }
                                    .bid-main{
                                        padding: 24px 32px;
                                        background-color: $Dark-bg-quaternary;
                                        border-radius: 24px;
                                        margin-right: 30px;
                                        @include breakpoint($secreen-max-sm){
                                            margin: 0 0 30px 0;
                                        }
                                        .bid{
                                            color: $Dark-Gray-Shade-2;
                                        }
                                        .bid-head{
                                            padding: 4px 0;
                                            color: $White-Color;
                                        }
                                        .dollor{
                                            color: $Dark-Gray-Shade-2;
                                        }
                                    }
                                    .auction-main{
                                        padding: 24px 32px;
                                        background-color: $Dark-bg-quaternary;
                                        border-radius: 24px;
                                        .acution{
                                            color: $Gray-Shade-2;
                                        }
                                        .timer{
                                            display: flex;
                                            .hours-main,.minutes-main,.seconds-main{
                                                .time-inr{
                                                    color: $White-Color;
                                                    padding: 4px 0;
                                                }
                                                .hours-p,.minutes-p,.seconds-p{
                                                    color: $Dark-Gray-Shade-2;
                                                }
                                                .desktop{
                                                    @include breakpoint($secreen-xxsx){
                                                        display: none;
                                                    }
                                                }
                                                .mobile{
                                                    display: none;
                                                    @include breakpoint($secreen-xxsx){
                                                        display: block;
                                                    }
                                                }
                                            }
                                        }
                                        .main{
                                            &:not(:last-child){
                                                margin-right: 24px;
                                            }
                                        }
                                    }
                                }
                                .action-otr{
                                    display: flex;
                                    @include breakpoint($secreen-xxs){
                                        flex-direction: column;
                                        text-align: center;
                                    }
                                    .btn-create{
                                        margin-right: 16px;
                                        &:hover{
                                            box-shadow: none;
                                        }
                                        @include breakpoint($secreen-xxs){
                                            margin: 0 0 16px 0;
                                        }
                                    }
                                    .btn-wallet{
                                        color: $White-Color;
                                        &:hover{
                                            box-shadow: none;
                                        }
                                    }
                                }
                            }
                        }
                        .col-img-otr{
                            position: relative;
                            @include breakpoint($secreen-max-xxl){
                                width: 43.666667% !important;
                            }
                            @include breakpoint($secreen-max-md){
                                width: 100% !important;
                            }
                            .col-img-inr{
                                position: relative;
                                padding: 36px 36px 32px 36px;
                                background-color: $Dark-Black-Color;
                                border-radius: 24px;
                                margin: 0 0 0 50px;
                                @include breakpoint($secreen-max-xxl){
                                    margin-right: 0;
                                }
                                @include breakpoint($secreen-max-md){
                                    margin: 128px auto;
                                    width: 70%;
                                    z-index: 300;
                                }
                                @include breakpoint($secreen-max-sm){
                                    width: 100%;
                                    margin: 80px auto;
                                }
                                @include breakpoint($secreen-xs){
                                    padding: 28px 28px 24px 28px;
                                }
                                .img-otr{
                                    border-radius: 16px;
                                    overflow: hidden;
                                    .artwork-img{
                                        border-radius: 16px;
                                    }
                                }
                                .create-otr{
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    padding: 24px 0 0 0;
                                    position: relative;
                                    .create-inr{
                                        display: flex;
                                        align-items: center;
                                        @include breakpoint($secreen-xs){
                                            align-items: baseline;
                                            flex-direction: column;
                                        }
                                        &:hover{
                                            .hover-box{
                                                bottom: 60px;
                                                padding-bottom: 25px;
                                                opacity: 1;
                                                visibility: visible;
                                            }
                                        }
                                        .create-img{
                                            position: relative;
                                            .img-create{
                                                width: 60px;
                                                border-radius: 16px;
                                                @include breakpoint($secreen-xs){
                                                    width: 52px;
                                                    border-radius: 12px;
                                                }
                                            }
                                            .check-otr{
                                                position: absolute;
                                                bottom: 0;
                                                right: 0;
                                                width: 20px;
                                                height: 20px;
                                                background-color: $Primary-Color-Home-1;
                                                border-radius: 100%;
                                                border: 2px solid $White-Color;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                .check-icon{
                                                    width: 10px;
                                                }
                                            }
                                        }
                                        .create-content{
                                            position: relative;
                                            top: 1px;
                                            margin-left: 16px;
                                            @include breakpoint($secreen-xs){
                                                margin: 8px 0 0 0;
                                            }
                                            .create-p{
                                                color: $Dark-Gray-Shade-2;
                                            }
                                            .create-pb{
                                                color: $White-Color;
                                                @include breakpoint($secreen-xs){
                                                    margin-top: -2px;
                                                }
                                            }
                                        }
                                    }
                                    .hover-box{
                                        position: absolute;
                                        bottom: 60px;
                                        left: 0;
                                        padding-bottom: 50px;
                                        opacity: 0;
                                        visibility: hidden;
                                        transition: .3s;
                                        .hover-box-inr{
                                            width: 330px;
                                            background-color: $Dark-Black-2-Color;
                                            border-radius: 24px;
                                            padding: 32px;
                                            backdrop-filter: 15px;
                                            .user-info{
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                                .create-img{
                                                    position: relative;
                                                    .img-create{
                                                        width: 60px;
                                                        border-radius: 16px;
                                                    }
                                                    .check-otr{
                                                        position: absolute;
                                                        bottom: 0;
                                                        right: 0;
                                                        width: 20px;
                                                        height: 20px;
                                                        background-color: $Primary-Color-Home-1;
                                                        border-radius: 100%;
                                                        border: 2px solid $White-Color;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        .check-icon{
                                                            width: 10px;
                                                        }
                                                    }
                                                }
                                                .follow-btn{
                                                    width: auto;
                                                    color: $White-Color;
                                                    &:hover{
                                                        box-shadow: none;
                                                    }
                                                }
                                            }
                                            .post-title{
                                                padding: 20px 0 8px 0;
                                                color: $White-Color;
                                            }
                                            .address-main{
                                                display: inline-flex;
                                                align-items: center;
                                                padding: 0 0 16px 0;
                                                .address{
                                                    color: $Dark-Gray-Shade-2;
                                                    padding-right: 8px;
                                                }
                                                .copy-icon{
                                                    path{
                                                        stroke: $Dark-Gray-Shade-1;
                                                    }
                                                }
                                            }
                                            .post-desc{
                                                color: $Dark-Gray-Shade-1;
                                            }
                                        }
                                    }
                                    .heart-main{
                                        display: flex;
                                        align-items: center;
                                        .heart-otr{
                                            width: 40px;
                                            height: 40px;
                                            border-radius: 100%;
                                            margin-right: 12px;
                                            background-color: $Dark-bg-Secondry;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            .heart-icon{
                                                position: relative;
                                                top: 1px;
                                                path{
                                                    transition: .5s;
                                                }
                                            }
                                        }
                                        .num{
                                            color: $Dark-Gray-Shade-2;
                                        }
                                    }
                                }
                            }
                            .bg-gradient{
                                display: none;
                                position: absolute;
                                right: -10px;
                                top: 0;
                                height: 100%;
                                background-color: $Gray-Shade-4;
                                border-radius: 24px 0 0 24px;
                                z-index: -1;
                                @include breakpoint($secreen-max-md){
                                    width: 110%;
                                    border-radius: 0;
                                    height: 100%;
                                    display: block;
                                }
                                .bg-gradient-inr{
                                    position: relative;
                                    overflow: hidden;
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 24px 0 0 24px;
                                    @include breakpoint($secreen-max-md){
                                        border-radius: 0;
                                    }
                                    &::after{
                                        content: "";
                                        position: absolute;
                                        width: 500px;
                                        height: 500px;
                                        background-color: $Secondary-Color-Home-1;
                                        border-radius: 100%;
                                        bottom: -150px;
                                        right: -150px;
                                        opacity: 50%;
                                        filter: blur(50px);
                                    }
                                    &::before{
                                        content: "";
                                        position: absolute;
                                        width: 500px;
                                        height: 500px;
                                        background-color: $Primary-Color-Home-1;
                                        border-radius: 100%;
                                        top: -150px;
                                        left: -150px;
                                        opacity: 50%;
                                        filter: blur(50px);
                                    }
                                    .marq-ul{
                                        display: flex;
                                        align-items: center;
                                        .marq-li{
                                            font-size: 100px;
                                            line-height: 130px;
                                            font-family: $font-family-DMSans-Bold;
                                            &:not(:last-child){
                                                margin-right: 100px;
                                            }
                                        }
                                    }
                                    .marq1{
                                        position: absolute;
                                        top: 30px;
                                        opacity: 7%;
                                    }
                                    .marq2{
                                        position: absolute;
                                        top: 600px;
                                        opacity: 7%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .bg-gradient{
                position: absolute;
                right: 0;
                top: 0;
                width: 45%;
                height: 100%;
                background-color: $Gray-Shade-4;
                border-radius: 24px 0 0 24px;
                @include breakpoint($secreen-max-md){
                   display: none;
                }
                .bg-gradient-inr{
                    position: relative;
                    overflow: hidden;
                    width: 100%;
                    height: 100%;
                    border-radius: 24px 0 0 24px;
                    @include breakpoint($secreen-max-md){
                        border-radius: 0;
                    }
                    &::after{
                        content: "";
                        position: absolute;
                        width: 500px;
                        height: 500px;
                        background-color: $Secondary-Color-Home-1;
                        border-radius: 100%;
                        bottom: -150px;
                        right: -150px;
                        opacity: 50%;
                        filter: blur(50px);
                    }
                    &::before{
                        content: "";
                        position: absolute;
                        width: 500px;
                        height: 500px;
                        background-color: $Primary-Color-Home-1;
                        border-radius: 100%;
                        top: -150px;
                        left: -150px;
                        opacity: 50%;
                        filter: blur(50px);
                    }
                    .marq-ul{
                        display: flex;
                        align-items: center;
                        .marq-li{
                            font-size: 100px;
                            line-height: 130px;
                            font-family: $font-family-DMSans-Bold;
                            &:not(:last-child){
                                margin-right: 100px;
                            }
                        }
                    }
                    .marq1{
                        position: absolute;
                        top: 30px;
                        opacity: 7%;
                    }
                    .marq2{
                        position: absolute;
                        top: 600px;
                        opacity: 7%;
                    }
                }
            }
        }
    }
}

/*------------------------------------------
        Hero Section End Here
-------------------------------------------*/

/*------------------------------------------
        Live Auction's Start Here
-------------------------------------------*/

.live-acution-home2-dark{
    overflow: hidden;
    padding: 0 0 128px 0;
    @include breakpoint($secreen-max-sm){
        padding: 0 0 80px 0 !important;
    }
    .container-fluid{
        .live-auction-inr{
            .heading-otr{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .head-otr{
                    display: flex;
                    align-items: center;
                    .dot{
                        display: flex;
                        width: 24px;
                        height: 24px;
                        background-color: $Primary-Color-Home-1;
                        border-radius: 100%;
                        margin-right: 16px;
                        @include dot-animation;
                        animation: dot-animation 1s linear infinite;
                    }
                    .heading{
                        color: $White-Color;
                    }
                }
                .view-all{
                    display: flex;
                    align-items: center;
                    transition: .3s;
                    @include breakpoint($secreen-xs){
                        display: none;
                    }
                    &:hover{
                        .arrow-icon{
                            margin-left: 16px;
                        }
                    }
                    .View-p{
                        color: $Primary-Color-Home-1;
                    }
                    .arrow-icon{
                        transition: .3s;
                        margin-left: 12px;
                    }
                }
            }
            .line{
                display: flex;
                flex: 1;
                height: 1px;
                background-color: $Dark-bg-tertiary;
                margin: 36px 0 48px 0;
            }
            .row-custom{
                .col-otr{
                    .col-inr{
                        padding: 24px;
                        border: 1px solid $Dark-bg-tertiary;
                        border-radius: 24px;
                        .img-otr{
                            position: relative;
                            .img-inr{
                                border-radius: 16px;
                            }
                            .timer-otr{
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 120px;
                                height: 40px;
                                border: 1px solid $White-Color;
                                background-color: $Primary-Color-Home-1;
                                border-radius: 12px;
                                .countdown{
                                    position: absolute;
                                    left: 65%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                    color: $White-Color;
                                    width: 100%;
                                }
                            }
                        }
                        .time-main{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 24px 0 20px 0;
                            .users-main{
                                position: relative;
                                display: flex;
                                align-items: center;
                                .create-img-otr{
                                    position: relative;
                                    &:hover{
                                        .hover-box1{
                                            bottom: 40px;
                                            padding-bottom: 25px;
                                            opacity: 1;
                                            visibility: visible;
                                        }
                                        .hover-box2{
                                            bottom: 40px;
                                            padding-bottom: 25px;
                                            opacity: 1;
                                            visibility: visible;
                                        }
                                    }
                                    .create-img{
                                        .img-create{
                                            width: 40px;
                                            border-radius: 12px;
                                        }
                                        .check-otr{
                                            position: absolute;
                                            bottom: 0;
                                            right: 0;
                                            width: 16px;
                                            height: 16px;
                                            background-color: $Primary-Color-Home-1;
                                            border-radius: 100%;
                                            border: 2px solid $White-Color;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            .check-icon{
                                                width: 8px;
                                            }
                                        }
                                    }
                                    .left-create{
                                        margin-right: 8px;
                                    }
                                    .hover-box{
                                        position: absolute;
                                        bottom: 60px;
                                        padding-bottom: 50px;
                                        transition: .3s;
                                        z-index: 10;
                                        .hover-box-inr{
                                            width: 330px;
                                            background-color: $Dark-Black-2-Color;
                                            border-radius: 24px;
                                            padding: 32px;
                                            backdrop-filter: 15px;
                                            @include breakpoint($secreen-max-xxxxl){
                                                width: 100%;
                                            }
                                            .user-info{
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                                .create-img{
                                                    position: relative;
                                                    .img-create{
                                                        width: 60px;
                                                        border-radius: 16px;
                                                    }
                                                    .check-otr{
                                                        position: absolute;
                                                        bottom: 0;
                                                        right: 0;
                                                        width: 20px;
                                                        height: 20px;
                                                        background-color: $Primary-Color-Home-1;
                                                        border-radius: 100%;
                                                        border: 2px solid $White-Color;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        .check-icon{
                                                            width: 10px;
                                                        }
                                                    }
                                                }
                                                .follow-btn{
                                                    width: auto;
                                                    color: $White-Color;
                                                    &:hover{
                                                        box-shadow: none;
                                                    }
                                                }
                                            }
                                            .post-title{
                                                padding: 20px 0 8px 0;
                                                color: $White-Color;
                                            }
                                            .address-main{
                                                display: inline-flex;
                                                align-items: center;
                                                padding: 0 0 16px 0;
                                                .address{
                                                    color: $Dark-Gray-Shade-2;
                                                    padding-right: 8px;
                                                }
                                                .copy-icon{
                                                    path{
                                                        stroke: $Dark-Gray-Shade-1;
                                                    }
                                                }
                                            }
                                            .post-desc{
                                                color: $Dark-Gray-Shade-1;
                                            }
                                        }
                                    }    
                                    .hover-box1{
                                        left: -20px;
                                        opacity: 0;
                                        visibility: hidden;
                                        @include breakpoint($secreen-max-md){
                                            display: none;
                                        }
                                    }
                                    .hover-box2{
                                        left: -70px;
                                        opacity: 0;
                                        visibility: hidden;
                                        @include breakpoint($secreen-max-md){
                                            display: none;
                                        }
                                    }
                                }
                            }
                            .heart-main{
                                display: flex;
                                align-items: center;
                                .heart-otr{
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 100%;
                                    margin-right: 12px;
                                    background-color: $Dark-bg-quaternary;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    .heart-icon{
                                        position: relative;
                                        top: 1px;
                                        path{
                                            transition: .5s;
                                        }
                                    }
                                }
                                .num{
                                    color: $Dark-Gray-Shade-2;
                                }
                            }
                        }
                        .box-head{
                            color: $White-Color;
                            padding: 0 0 24px 0;
                            display: inline-flex;
                            transition: .2s;
                            &:hover{
                                color: $Primary-Color-Home-1;
                            }
                        }
                        .bid-main{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 12px 20px;
                            background-color: $Dark-bg-quaternary;
                            border-radius: 16px;
                            .bid{
                                color: $Dark-Gray-Shade-2;
                            }
                            .eth{
                                color: $White-Color;
                            }
                        }
                    }
                }
            }
        }
    }
}

.live-acution-home2-dark .owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 48px !important;
    padding-right: 0;
    @include breakpoint($secreen-xxsx){
        margin-top: 18px !important;
    }
}

.live-acution-home2-dark .owl-theme .owl-dots .owl-dot span{
    background: $Gray-Shade-3;
}
.live-acution-home2-dark .owl-theme .owl-dots .active span{
    background: $Primary-Color-Home-1;
}

/*------------------------------------------
        Live Auction's End Here
-------------------------------------------*/

/*------------------------------------------
        Live Auction's Start Here
-------------------------------------------*/

.creator-main-home2-dark{
    overflow: hidden;
    padding: 0 0 128px 0;
    @include breakpoint($secreen-max-sm){
        padding: 0 0 80px 0 !important;
    }
    .container-fluid{
        .creator-main-inr{
            .heading-otr{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .head-otr{
                    display: flex;
                    align-items: center;
                    .heading{
                        color: $White-Color;
                    }
                }
                .view-all{
                    display: flex;
                    align-items: center;
                    transition: .3s;
                    @include breakpoint($secreen-xs){
                        display: none;
                    }
                    &:hover{
                        .arrow-icon{
                            margin-left: 16px;
                        }
                    }
                    .View-p{
                        color: $Primary-Color-Home-1;
                    }
                    .arrow-icon{
                        transition: .3s;
                        margin-left: 12px;
                    }
                }
            }
            .line{
                display: flex;
                flex: 1;
                height: .7px;
                background-color: $Dark-bg-tertiary;
                margin: 36px 0 48px 0;
            }
            .row-custom{
                .creator-otr{
                    @include breakpoint($secreen-custom){
                        width: 50%;
                    }
                    .create-inr{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        padding: 24px 0 16px 0;
                        border-radius: 24px;
                        border: 1px solid $Dark-bg-tertiary;
                        .create-img{
                            position: relative;
                            .img-create{
                                width: 60px;
                                border-radius: 16px;
                            }
                            .check-otr{
                                position: absolute;
                                bottom: 0;
                                right: 0;
                                width: 20px;
                                height: 20px;
                                background-color: $Primary-Color-Home-1;
                                border-radius: 100%;
                                border: 2px solid $White-Color;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                .check-icon{
                                    width: 10px;
                                }
                            }
                        }
                        .create-content{
                            padding: 16px 0 0 0;
                            .create-p{
                                color: $Dark-Gray-Shade-2;
                            }
                            .create-pb{
                                color: $White-Color;
                            }
                        }
                    }
                    .box1,.box2,.box3,.box4,.box5,.box6{
                        margin-bottom: 24px;
                    }
                    .box1,.box2,.box3,.box4,.box5,.box6,.box7,.box8,.box9{
                        @include breakpoint($secreen-max-sm){
                            margin-bottom: 24px;
                        }
                    }
                }
            }
            .responsive{
                display: none;
                visibility: hidden;
                opacity: 0;
                .view-all{
                    display: flex;
                    align-items: center;
                    transition: .3s;
                    &:hover{
                        .arrow-icon{
                            margin-left: 16px;
                        }
                    }
                    .View-p{
                        color: $Primary-Color-Home-1;
                    }
                    .arrow-icon{
                        transition: .3s;
                        margin-left: 12px;
                    }
                }
                @include breakpoint($secreen-xs){
                    display: block;
                    opacity: 1;
                    visibility: visible;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 48px 0 0 0;
                }
            }
        }
    }
}

/*------------------------------------------
        Live Auction's End Here
-------------------------------------------*/

/*------------------------------------------
        Live Auction's Start Here
-------------------------------------------*/

.popular-collection-home2-dark{
    overflow: hidden;
    padding: 0 0 256px 0;
    @include breakpoint($secreen-max-sm){
        padding: 0 0 200px 0 !important;
    }
    @include breakpoint($secreen-xs){
        padding: 0 0 80px 0 !important;
    }
    .container-fluid{
        .popular-collection-inr{
            .heading-otr{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .head-otr{
                    display: flex;
                    align-items: center;
                    .heading{
                        color: $White-Color;
                    }
                }
            }
            .line{
                display: flex;
                flex: 1;
                height: .7px;
                background-color: $Dark-bg-tertiary;
                margin: 36px 0 48px 0;
            }
            .row-custom{
                .col-otr{
                    .col-inr{
                        position: relative;
                        .img-otr{
                            position: relative;
                            border-radius: 24px;
                            &::after{
                                content: "";
                                position: absolute;
                                left: 50%;
                                transform: translateX(-50%);
                                bottom: -16px;
                                width: calc(100% - 32px);
                                height: 100%;
                                border-radius: 24px;
                                background-color: $Dark-bg-tertiary;
                            }
                            &::before{
                                content: "";
                                position: absolute;
                                left: 50%;
                                transform: translateX(-50%);
                                bottom: -32px;
                                width: calc(100% - 64px);
                                height: 100%;
                                border-radius: 24px;
                                background-color: $Dark-bg-quaternary;
                            }
                            .img{
                                width: auto;
                                border-radius: 24px;
                                position: relative;
                                z-index: 1;
                            }
                        }
                        .user-profile{
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            bottom: -131px;
                            z-index: 1;
                            text-align: center;
                            width: 100%;
                            .user-img-otr{
                                width: 60px;
                                margin: 0 auto;
                                .user-img-inr{
                                    border: 4px solid $Dark-Black-Color;
                                    border-radius: 16px;
                                    width: 64px;
                                }
                            }
                            .user-content{
                                padding: 16px 0 0 0;
                                .title{
                                    display: inline;
                                    color: $White-Color;
                                    padding: 0 0 4px 0;
                                    transition: .2s;
                                    &:hover{
                                        color: $Primary-Color-Home-1;
                                    }
                                }
                                .collect-by{
                                    color: $Dark-Gray-Shade-1;
                                    .username{
                                        color: $Primary-Color-Home-1;
                                        display: inline;
                                    }
                                }
                            }
                        }
                    }
                }   
            }
        }
    }
}

.popular-collection-home2-dark .owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 172px;
    position: relative;
    right: -12px;
}

.popular-collection-home2-dark .owl-theme .owl-dots .owl-dot span{
    background: $Gray-Shade-3;
}
.popular-collection-home2-dark .owl-theme .owl-dots .active span{
    background: $Primary-Color-Home-1;
}

/*------------------------------------------
        Live Auction's End Here
-------------------------------------------*/

/*------------------------------------------
        Explore Artworks Start Here
-------------------------------------------*/

.explore-artwork-home2-dark{
    overflow: hidden;
    padding: 0 0 128px 0;
    @include breakpoint($secreen-max-sm){
        padding: 0 0 80px 0 !important;
    }
    .container-fluid{
        .explore-artwork-inr{
            .heading-otr{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .head-otr{
                    display: flex;
                    align-items: center;
                    .heading{
                        color: $White-Color;
                    }
                }
                .view-all{
                    display: flex;
                    align-items: center;
                    transition: .3s;
                    @include breakpoint($secreen-xs){
                        display: none;
                    }
                    &:hover{
                        .arrow-icon{
                            margin-left: 16px;
                        }
                    }
                    .View-p{
                        color: $Primary-Color-Home-1;
                    }
                    .arrow-icon{
                        transition: .3s;
                        margin-left: 12px;
                    }
                }
            }
            .teb-main{
                @include breakpoint($secreen-max-md){
                    overflow-x: scroll;
                }
                &::-webkit-scrollbar {
                    width: 100%;
                    height: .1px;
                    background-color: #EEEEEE;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: #ACACAC;
                    border-radius: 2px;
                }
                .tabs{
                    display: flex;
                    align-items: center;
                    @include breakpoint($secreen-max-md){
                        width: 790px;
                    }
                    .tab-link{
                        margin-top: 24px;
                        &:not(:last-child){
                            margin-right: 24px;
                        }
                        .tab-p{
                            padding: 4px 16px;
                            border-radius: 12px;
                            background-color: $Dark-bg-quaternary;
                            color: $Dark-Gray-Shade-1;
                            transition: .3s;
                            &:hover{
                                cursor: pointer;
                            }
                        }
                    }
                    .active{
                        .tab-p{
                            background-color: $Primary-Color-Home-1;
                            color: $White-Color;
                        }
                    }
                }
            }
            .line{
                display: flex;
                flex: 1;
                height: 1px;
                background-color: $Dark-bg-tertiary;
                margin: 48px 0;
            }
            .row-custom-main{
                .tab-content{
                    display: none;
                    opacity: 0;
                    transform: translateY(15px);
                    animation: fadeIn 0.5s ease 1 forwards;
                    .row-custom-inr{
                        .col-otr{
                            padding: 0 12px;
                            @include breakpoint($secreen-max-xxl){
                                width: 33%;
                            }
                            @include breakpoint($secreen-max-md){
                                width: 50%;
                            }
                            @include breakpoint($secreen-custom){
                                width: 100%;
                            }
                            .col-inr{
                                padding: 24px;
                                border: 1px solid $Dark-bg-tertiary;
                                border-radius: 24px;
                                .img-otr{
                                    position: relative;
                                    border-radius: 16px;
                                    overflow: hidden;
                                    .img-inr{
                                        border-radius: 16px;
                                    }
                                    .timer-otr{
                                        position: absolute;
                                        bottom: 0;
                                        left: 3px;
                                        width: 120px;
                                        height: 40px;
                                        border: 1px solid $White-Color;
                                        background-color: $Primary-Color-Home-1;
                                        border-radius: 12px;
                                        .countdown{
                                            position: absolute;
                                            left: 65%;
                                            top: 50%;
                                            transform: translate(-50%, -50%);
                                            color: $White-Color;
                                            width: 100%;
                                        }
                                    }
                                }
                                .time-main{
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    padding: 24px 0 20px 0;
                                    .users-main{
                                        position: relative;
                                        display: flex;
                                        align-items: center;
                                        .create-img-otr{
                                            position: relative;
                                            &:hover{
                                                .hover-box1{
                                                    bottom: 40px;
                                                    padding-bottom: 25px;
                                                    opacity: 1;
                                                    visibility: visible;
                                                }
                                                .hover-box2{
                                                    bottom: 40px;
                                                    padding-bottom: 25px;
                                                    opacity: 1;
                                                    visibility: visible;
                                                }
                                            }
                                            .create-img{
                                                .img-create{
                                                    width: 40px;
                                                    border-radius: 12px;
                                                }
                                                .check-otr{
                                                    position: absolute;
                                                    bottom: 0;
                                                    right: 0;
                                                    width: 16px;
                                                    height: 16px;
                                                    background-color: $Primary-Color-Home-1;
                                                    border-radius: 100%;
                                                    border: 2px solid $White-Color;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    .check-icon{
                                                        width: 8px;
                                                    }
                                                }
                                            }
                                            .left-create{
                                                margin-right: 8px;
                                            }
                                            .hover-box{
                                                position: absolute;
                                                bottom: 60px;
                                                padding-bottom: 50px;
                                                transition: .3s;
                                                z-index: 10;
                                                .hover-box-inr{
                                                    width: 330px;
                                                    background-color: $Dark-Black-2-Color;
                                                    border-radius: 24px;
                                                    padding: 32px;
                                                    backdrop-filter: 15px;
                                                    .user-info{
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: space-between;
                                                        .create-img{
                                                            position: relative;
                                                            .img-create{
                                                                width: 60px;
                                                                border-radius: 16px;
                                                            }
                                                            .check-otr{
                                                                position: absolute;
                                                                bottom: 0;
                                                                right: 0;
                                                                width: 20px;
                                                                height: 20px;
                                                                background-color: $Primary-Color-Home-1;
                                                                border-radius: 100%;
                                                                border: 2px solid $White-Color;
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: center;
                                                                .check-icon{
                                                                    width: 10px;
                                                                }
                                                            }
                                                        }
                                                        .follow-btn{
                                                            width: auto;
                                                            color: $White-Color;
                                                            &:hover{
                                                                box-shadow: none;
                                                            }
                                                        }
                                                    }
                                                    .post-title{
                                                        padding: 20px 0 8px 0;
                                                        color: $White-Color;
                                                    }
                                                    .address-main{
                                                        display: inline-flex;
                                                        align-items: center;
                                                        padding: 0 0 16px 0;
                                                        .address{
                                                            color: $Dark-Gray-Shade-2;
                                                            padding-right: 8px;
                                                        }
                                                        .copy-icon{
                                                            path{
                                                                stroke: $Dark-Gray-Shade-1;
                                                            }
                                                        }
                                                    }
                                                    .post-desc{
                                                        color: $Dark-Gray-Shade-1;
                                                    }
                                                }
                                            }    
                                            .hover-box1{
                                                left: -20px;
                                                opacity: 0;
                                                visibility: hidden;
                                            }
                                            .hover-box2{
                                                left: -70px;
                                                opacity: 0;
                                                visibility: hidden;
                                            }
                                        }
                                    }
                                    .heart-main{
                                        display: flex;
                                        align-items: center;
                                        .heart-otr{
                                            width: 40px;
                                            height: 40px;
                                            border-radius: 100%;
                                            margin-right: 12px;
                                            background-color: $Dark-bg-quaternary;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            .heart-icon{
                                                position: relative;
                                                top: 1px;
                                                path{
                                                    transition: .5s;
                                                }
                                            }
                                        }
                                        .num{
                                            color: $Dark-Gray-Shade-2;
                                        }
                                    }
                                }
                                .box-head{
                                    color: $White-Color;
                                    padding: 0 0 24px 0;
                                    display: inline-flex;
                                    transition: .2s;
                                    &:hover{
                                        color: $Primary-Color-Home-1;
                                    }
                                }
                                .bid-main{
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    padding: 12px 20px;
                                    background-color: $Dark-bg-quaternary;
                                    border-radius: 16px;
                                    .bid{
                                        color: $Dark-Gray-Shade-2;
                                    }
                                    .eth{
                                        color: $White-Color;
                                    }
                                }
                            }
                            .box1,.box2,.box3,.box4{
                                margin-bottom: 24px;
                            }
                            .box1,.box2,.box3,.box4,.box5,.box6{
                                @include breakpoint($secreen-max-md){
                                    margin-bottom: 24px;
                                }
                            }
                            .box1,.box2,.box3,.box4,.box5,.box6,.box7{
                                @include breakpoint($secreen-custom){
                                    margin-bottom: 24px;
                                }
                            }
                        }
                    }
                }
                .active{
                    display: block;
                }
            }
            .responsive{
                display: none;
                visibility: hidden;
                opacity: 0;
                .view-all{
                    display: flex;
                    align-items: center;
                    transition: .3s;
                    &:hover{
                        .arrow-icon{
                            margin-left: 16px;
                        }
                    }
                    .View-p{
                        color: $Primary-Color-Home-1;
                    }
                    .arrow-icon{
                        transition: .3s;
                        margin-left: 12px;
                    }
                }
                @include breakpoint($secreen-xs){
                    display: block;
                    opacity: 1;
                    visibility: visible;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 48px 0 0 0;
                }
            }
        }
    }
}

/*------------------------------------------
        Explore Artworks End Here
-------------------------------------------*/

/*------------------------------------------
          Work Start Here
-------------------------------------------*/

.work-main-home2-dark{
    margin: 0 0 128px 0;
    @include breakpoint($secreen-max-sm){
        margin: 0 0 80px 0;
    }
    .container-fluid{
        .wrapper{
            display: flex;
            align-items: center;
            padding: 0 0 36px 0;
            border-bottom: 1px solid $Dark-bg-tertiary;
            margin: 0 0 48px 0;
            @include breakpoint($secreen-max-sm){
                flex-direction: column;
                align-items: flex-start;
            }
            .head{
                margin: 0 48px 0 0;
                color: $White-Color;
                @include breakpoint($secreen-max-sm){
                    margin: 0 0 24px 0;
                }
            }
            .teb-main{
                .tabs{
                    display: flex;
                    align-items: center;
                    .tab-link-work{
                        &:not(:last-child){
                            margin-right: 24px;
                        }
                        .tab-p{
                            padding: 4px 16px;
                            border-radius: 12px;
                            background-color: $Dark-bg-quaternary;
                            color: $Dark-Gray-Shade-1;
                            transition: .3s;
                            &:hover{
                                cursor: pointer;
                            }
                            @include breakpoint($secreen-xxsx){
                                padding: 4px 15px;
                            }
                        }
                    }
                    .active{
                        .tab-p{
                            background-color: $Primary-Color-Home-1;
                            color: $White-Color;
                        }
                    }
                }
            }
        }
        .row-work{
            .tab-content{
                display: none;
                opacity: 0;
                transform: translateY(15px);
                animation: fadeIn 0.5s ease 1 forwards;
                .row-work-inr{
                    .col-work-otr{
                        .col-work-inr{
                            background-color: $Dark-bg-quaternary;
                            border-radius: 24px;
                            .icon-otr{
                                display: flex;
                                align-items: center;
                                background-color: $Dark-bg-tertiary;
                                border-radius: 24px;
                                padding: 24px 28px 23px 28px;
                                .icon-inr{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    position: relative;
                                    margin: 0 20px 0 0;
                                    .bg-icon{
                                        width: 60px;
                                        height: 60px;
                                        border-radius: 16px;
                                        background-color: $Primary-Color-Home-1;
                                    }
                                    .icon{
                                        position: absolute;
                                        z-index: 2;
                                    }
                                }
                                .heading{
                                    color: $White-Color;
                                }
                            }
                            .desc{
                                color: $Dark-Gray-Shade-1;
                                padding: 24px 28px;
                            }
                        }
                        .box1,.box2{
                            @include breakpoint($secreen-max-md){
                                margin-bottom: 24px;
                            }
                        }
                    }
                    .col-work-otr{
                        .box2{
                            display: flex;
                            justify-content: center;
                            flex-direction: column-reverse;
                            @include breakpoint($secreen-max-md){
                                flex-direction: column;
                            }
                            .icon-otr{
                                display: flex;
                                align-items: center;
                                width: 100%;
                                .icon-inr{
                                    .bg-icon{
                                        background-color: $Secondary-Color-Home-1;
                                    }
                                    .icon{
                                    }
                                }
                                .heading{
                                }
                            }
                            .desc{
                            }
                        }
                    }
                }
            }
            .active{
                display: block;
            }
        }
    }
}

/*------------------------------------------
          Work End Here
-------------------------------------------*/

/*------------------------------------------
        Call to Action Start Here
-------------------------------------------*/

.call-to-action-home2-dark{
    margin: 0 0 128px 0;
    @include breakpoint($secreen-max-sm){
        margin: 0 0 80px 0 !important;
    }
    .container-fluid{
        .call-to-action{
            display: flex;
            position: relative;
            padding: 96px 72px 104px 72px;
            background-color: $Dark-bg-quaternary;
            border-radius: 24px;
            overflow: hidden;
            @include breakpoint($secreen-xs){
                padding: 48px 28px 56px 28px;
            }
            .wrapper{
                position: relative;
                z-index: 10;
                .heading{
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-image: linear-gradient(to right, #366CE3, #D0FC00);
                    display: inline-block;
                }
                .desc{
                    color: $Dark-Gray-Shade-2;
                    padding: 4px 0 32px 0;
                }
                .action-otr{
                    display: flex;
                    @include breakpoint($secreen-xs){
                        flex-direction: column;
                        align-items: flex-start;
                    }
                    .btn-create{
                        margin-right: 16px;
                        &:hover{
                            box-shadow: none;
                        }
                        @include breakpoint($secreen-xs){
                            margin: 0 0 16px 0;
                        }
                    }
                    .btn-wallet{
                        color: $White-Color;
                        &:hover{
                            box-shadow: none;
                        }
                    }
                }
            }
            .img-otr{
                display: flex;
                align-items: center;
                position: absolute;
                top: -100px;
                right: -50px;
                z-index: 2;
                transform: rotate(-15deg);
                @include breakpoint($secreen-xl2){
                    display: none;
                }
                .img-inr{
                    &:not(:last-child){
                        margin: 0 30px 0 0;
                    }
                    .col-img-inr{
                        &:not(:last-child){
                            margin-bottom: 30px;
                        }
                        .about-img{
                            width: 200px;
                            border-radius: 16px;
                        }
                    }
                }
            }
        }
    }
}

/*------------------------------------------
        Call to Action End Here
-------------------------------------------*/

/*------------------------------------------
        Footer Section Start Here
-------------------------------------------*/

.footer-home2-dark{
    background-color: $Dark-bg-quaternary;
    padding: 128px 0;
    position: relative;
    overflow: hidden;
    @include breakpoint($secreen-max-sm){
        padding: 80px 0 !important;
    }
    .container-fluid{
        position: relative;
        z-index: 10;
        .wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0 96px 0;
            @include breakpoint($secreen-max-lg){
                flex-direction: column;
            }
            @include breakpoint($secreen-max-sm){
                padding: 0 0 80px 0;
            }
            .ethoz-head{
                color: $White-Color;
                @include breakpoint($secreen-xxs){
                    text-align: center;
                }
            }
            .input-main{
                display: flex;
                align-items: center;
                @include breakpoint($secreen-max-lg){
                    margin: 28px 0 32px 0;
                }
                @include breakpoint($secreen-xs){
                    flex-direction: column;
                    width: 100%;
                }
                .input{
                    width: 320px;
                    background-color: $Dark-bg-quaternary;
                    color: $Dark-Gray-Shade-2;
                    &::placeholder{
                        color: $Dark-Gray-Shade-2;
                    }
                    @include breakpoint($secreen-xs){
                        width: 100%;
                    }
                }
                .btn-subscribe{
                    border: none;
                    margin-left: 16px;
                    color: $White-Color;
                    @include breakpoint($secreen-xs){
                        margin: 16px 0 0 0;
                    }
                    &:hover{
                        background-color: $Primary-Color-Home-1;
                    }
                    &:focus{
                        box-shadow: none;
                        outline: none;
                    }
                }
            }
            .icon-ul{
                display: flex;
                align-items: center;
                .icon-li{
                    &:not(:last-child){
                        margin-right: 20px;
                    }
                    .icon-a{
                        .icon{
                            &:hover{
                                path{
                                    stroke: $Primary-Color-Home-1;
                                }
                            }
                            path{
                                stroke: $Dark-Gray-Shade-2;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }
        .row-custom{
            .col-language-otr{
                .col-language-inr{
                    @include breakpoint($secreen-max-md){
                        padding: 0 0 48px 0;
                    }
                    @include breakpoint($secreen-xs){
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .language-ul{
                        display: flex;
                        .language-li{
                            position: relative;
                            .language-a{
                                padding: 9px 24px;
                                border: 1px solid $Dark-bg-tertiary;
                                border-radius: 16px;
                                display: flex;
                                align-items: center;
                                &:hover{
                                    cursor: pointer;
                                }
                                .flag-img{
                                    width: 24px;
                                    height: 24px;
                                    border-radius: 100%;
                                }
                                .language{
                                    padding: 0 8px;
                                    color: $Dark-Gray-Shade-1;
                                }
                                .caret-down{
                                    position: relative;
                                    top: 1px;
                                    path{
                                        stroke: $Dark-Gray-Shade-1;
                                    }
                                }
                            }
                            .drop-ul{
                                padding: 20px 24px;
                                background-color: $Dark-Black-2-Color;
                                border-radius: 16px;
                                position: absolute;
                                width: 100%;
                                top: 62px;
                                display: none;
                                .drop-li{
                                    &:not(:last-child){
                                        margin-bottom: 8px;
                                    }
                                    .drop-a{
                                        display: inline-flex;
                                        align-items: center;
                                        &:hover{
                                            cursor: pointer;
                                        }
                                        .flag-img{
                                            width: 24px;
                                            height: 24px;
                                            border-radius: 100%;
                                        }
                                        .language{
                                            color: $Dark-Gray-Shade-1;
                                            padding-left: 8px;
                                        }
                                    }
                                }
                            }
                            .block{
                                display: block;
                            }
                        }
                    }
                }
            }
            .col-nav-otr{
                .col-nav-inr{
                    @include breakpoint($secreen-max-md){
                        padding: 0 0 48px 0;
                    }
                    @include breakpoint($secreen-xs){
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        text-align: center;
                    }
                    .nav-head{
                        color: $White-Color;
                        padding: 0 0 16px 0;
                    }
                    .nav-ul{
                        .nav-li{
                            &:not(:last-child){
                                margin-bottom: 8px;
                            }
                            .nav-a{
                                display: inline;
                                color: $Dark-Gray-Shade-1;
                                transition: .3s;
                                &:hover{
                                    color: $Primary-Color-Home-1;
                                }
                            }
                        }
                    }
                }
            }
            .col-explore-otr{
                .col-explore-inr{
                    padding-left: 50px;
                    @include breakpoint($secreen-max-xxxl){
                        padding-left: 30px;
                    }
                    @include breakpoint($secreen-xl2){
                        padding-left: 15px;
                    }
                    @include breakpoint($secreen-max-md){
                        padding: 0 0 48px 0;
                    }
                    @include breakpoint($secreen-xs){
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        text-align: center;
                    }
                    .explore-head{
                        color: $White-Color;
                        padding: 0 0 16px 0;
                    }
                    .explore-ul{
                        .explore-li{
                            &:not(:last-child){
                                margin-bottom: 8px;
                            }
                            .explore-a{
                                display: inline;
                                color: $Dark-Gray-Shade-1;
                                transition: .3s;
                                &:hover{
                                    color: $Primary-Color-Home-1;
                                }
                            }
                        }
                    }
                }
            }
            .col-community-otr{
                .col-community-inr{
                    padding-left: 120px;
                    @include breakpoint($secreen-max-xxxl){
                        padding-left: 70px;
                    }
                    @include breakpoint($secreen-xl2){
                        padding-left: 50px;
                    }
                    @include breakpoint($secreen-max-md){
                        padding: 0;
                    }
                    @include breakpoint($secreen-xs){
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        text-align: center;
                        padding: 0 0 48px 0;
                    }
                    .community-head{
                        color: $White-Color;
                        padding: 0 0 16px 0;
                    }
                    .community-ul{
                        .community-li{
                            &:not(:last-child){
                                margin-bottom: 8px;
                            }
                            .community-a{
                                display: inline;
                                color: $Dark-Gray-Shade-1;
                                transition: .3s;
                                &:hover{
                                    color: $Primary-Color-Home-1;
                                }
                            }
                        }
                    }
                }
            }
            .col-company-otr{
                .col-company-inr{
                    padding-left: 70px;
                    @include breakpoint($secreen-max-xxxl){
                        padding-left: 30px;
                    }
                    @include breakpoint($secreen-xl2){
                        padding-left: 0;
                    }
                    @include breakpoint($secreen-max-md){
                        padding: 0;
                    }
                    @include breakpoint($secreen-xs){
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        text-align: center;
                    }
                    .company-head{
                        color: $White-Color;
                        padding: 0 0 16px 0;
                    }
                    .company-ul{
                        .company-li{
                            &:not(:last-child){
                                margin-bottom: 8px;
                            }
                            .company-a{
                                display: inline;
                                color: $Dark-Gray-Shade-1;
                                transition: .3s;
                                &:hover{
                                    color: $Primary-Color-Home-1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*------------------------------------------
        Footer Section End Here
-------------------------------------------*/

/*------------------------------------------
        Copy Section Start Here
-------------------------------------------*/

.copy-otr-home2-dark{
    .container-fluid{
        .copy-inr{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px 0;
            @include breakpoint($secreen-xs){
                flex-direction: column;
                padding: 24px 0 16px 0;
            }
            .logo-otr{
                .logo{
                    width: 100px;
                }
            }
            .copy-name{
                color: $Dark-Gray-Shade-1;
                @include breakpoint($secreen-xs){
                    margin: 16px 0 6px 0;
                }
                .name{
                    display: inline;
                    color: $Primary-Color-Home-1;
                }
            }
            .all-rights{
                .all{
                    color: $Dark-Gray-Shade-1;
                }
            }
        }
    }
}

/*------------------------------------------
        Copy Section End Here
-------------------------------------------*/
