


.hero-landing-page{
    background-color: $Gray-Shade-4;
    position: relative;
    overflow: hidden;
    &::after{
        content: "";
        position: absolute;
        right: 200px;
        top: 50%;
        transform: translateY(-50%);
        width: 400px;
        height: 400px;
        border-radius: 100%;
        background-color: $Primary-Color-Home-1;
        filter: blur(300px);
    }
    .container-fluid{
        .row-custom{
            align-items: center;
            .col-content-otr{
                @include breakpoint($secreen-max-lg){
                    width: 70%;
                }
                @include breakpoint($secreen-max-md){
                    width: 100%;
                }
                .col-content-inr{
                    padding: 128px 0 144px 0;
                    position: relative;
                    z-index: 10;
                    .heading{
                        color: $Black-Color;
                    }
                    .desc{
                        color: $Gray-Shade-1;
                        padding: 16px 0 32px 0;
                    }
                    .icons-ul{
                        display: flex;
                        align-items: center;
                        .icons-li{
                            &:not(:last-child){
                                margin-right: 30px;
                            }
                            .icon{
                                height: 50px;
                            }
                        }
                    }
                }
            }
            .col-img-otr{
                .col-img-inr{
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 2;
                    @include breakpoint($secreen-max-md){
                        display: none;
                    }
                    .hero-img{
                        width: 800px;
                    }
                }
            }
        }
    }
    .circle{
        position: absolute;
        right: 300px;
        top: 0;
        opacity: 20%;
    }
}


.inner-pages .owl-dots{
    margin-top: 48px !important;
    padding-right: 0;
}

.inner-pages .owl-dots{
    @include breakpoint($secreen-xxsx){
        margin-top: 18px !important;
    }
}

.inner-pages .owl-dots{
    @include breakpoint($secreen-xxsx){
        margin-top: 18px !important;
    }
}

.inner-pages .owl-theme .owl-dots .owl-dot span{
    background: $Gray-Shade-3;
}

.inner-pages .owl-theme .owl-dots .active span{
    background: $Primary-Color-Home-1;
}
.inner-pages .owl-stage{
    display: flex;
    align-items: center;
}

.homepages-main{
    padding: 128px 0;
    overflow: hidden;
    @include breakpoint($secreen-max-sm){
        padding: 80px 0;
    }
    .container-fluid{
        .homepages-inr{
            .heading-otr{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .head-otr{
                    display: flex;
                    align-items: center;
                    .heading{
                        color: $Black-Color;
                    }
                }
            }
            .line{
                display: flex;
                flex: 1;
                height: 1px;
                background-color: $Gray-Shade-3;
                margin: 36px 0 48px 0;
            }
            .row-custom{
                .col-otr{
                    .col-inr{
                        .img-otr{
                            background-color: $Gray-Shade-4;
                            border-radius: 24px;
                            padding: 24px;
                            .img-inr{
                                border-radius: 16px;
                                box-shadow: $box-shadow-custom;
                            }
                        }
                        .card-head{
                            margin: 24px 0 0 0;
                            text-align: center;
                            color: $Black-Color;
                            transition: .2s;
                            &:hover{
                                color: $Primary-Color-Home-1;
                            }
                        }
                    }
                    .box1,.box2,.box3{
                        margin-bottom: 40px;
                    }
                }
            }
        }
    }
}

.inner-pages{
    overflow: hidden;
    padding: 0 0 128px 0;
    @include breakpoint($secreen-max-sm){
        padding: 0 0 80px 0;
    }
    .container-fluid{
        .live-auction-inr{
            .heading-otr{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .head-otr{
                    display: flex;
                    align-items: center;
                    .heading{
                        color: $Black-Color;
                    }
                }
            }
            .line{
                display: flex;
                flex: 1;
                height: 1px;
                background-color: $Gray-Shade-3;
                margin: 36px 0 48px 0;
            }
            .row-custom{
                .col-otr{
                    .col-inr{
                        .img-otr{
                            background-color: $Gray-Shade-4;
                            border-radius: 24px;
                            padding: 24px;
                            .img-inr{
                                border-radius: 16px;
                                box-shadow: $box-shadow-custom;
                            }
                        }
                        .card-head{
                            margin: 24px 0 0 0;
                            text-align: center;
                            color: $Black-Color;
                            transition: .2s;
                            &:hover{
                                color: $Primary-Color-Home-1;
                            }
                        }
                    }
                }
            }
        }
    }
}

.footer-landing{
    position: relative;
    overflow: hidden;
    background-color: $Gray-Shade-4;
    padding: 128px 0 140px 0;
    &::after{
        content: "";
        position: absolute;
        width: 400px;
        height: 400px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        background-color: $Primary-Color-Home-1;
        filter: blur(300px);
    }
    .container-fluid{
        .wrapper{
            text-align: center;
            position: relative;
            z-index: 10;
            .heading{
                color: $Black-Color;
                br{
                    @include breakpoint($secreen-max-md){
                        display: none;
                    }
                }
            }
            .desc{
                color: $Gray-Shade-1;
                padding: 16px 0 28px 0;
                br{
                    @include breakpoint($secreen-max-md){
                        display: none;
                    }
                }
            }
            .action-otr{
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .circle-1{
        position: absolute;
        right: 0;
        top: 0;
        opacity: 25%;
    }
    .circle-2{
        position: absolute;
        left: 0;
        bottom: 0;
        opacity: 25%;
    }
}
.copyright{
    padding: 32px 0;
    .container-fluid{
        .copy{
            color: $Gray-Shade-1;
            text-align: center;
            .visto{
                display: inline;
                color: $Primary-Color-Home-1;
            }
        }
    }
}